<template>
  <div>
    <h3 class="section-title">{{ $t("payment.section2") }}</h3>
    <section class="section payment-currency-section">
      <p class="amount-label">{{ $t("payment.amountToBeSent") }}</p>
      <div class="amount-input">
        <span>{{ amount }}</span>
        <button class="copy copy-1" @click="copyAmount" :disabled="copied">
          <img v-if="!copied" src="../assets/copy.svg" alt="copy" />
          <span v-if="!copied">{{ $t("payment.copy") }}</span>
          <img v-if="copied" src="../assets/done.svg" alt="checkmark" />
        </button>
      </div>
      <p class="amount-label">{{ $t("payment.currency") }}</p>
      <div>
        <payment-select
          :options="availableCoins"
          v-bind:value="value"
          @input="$emit('input', $event)"
        />
      </div>
      <div class="note">
        <div class="note-icon">
          <img alt="note" src="../assets/pin.svg" />
        </div>
        <p>{{ $t("payment.note") }}</p>
      </div>
    </section>
    <section class="section choosePayment">
      <p>
        {{ $t("payment.promocodeButton") }}
      </p>
      <button @click="open">{{ $t("payment.addPromo") }}</button>
    </section>
    <div v-if="opened" class="payment-modal">
      <div class="payment-modal-body">
        <p class="payment-modal-title pomocode-title">
          {{ $t("payment.promocodePlaceholder") }}
        </p>
        <p class="amount-label">{{ $t("payment.promocodeButton") }}</p>
        <input class="amount-input" v-model="promocode" />
        <div class="payment-modal-controls">
          <button class="outlined-button" @click="cancel">
            {{ $t("payment.cancel") }}
          </button>
          <button class="button" @click="submit" :disabled="!promocode">
            {{ $t("payment.submit") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    PaymentSelect: () => import("./payment-select.vue"),
  },

  props: {
    cryptoAmount: [String, Number],
    availableCoins: Array,
    value: [String, null],
  },

  data() {
    return {
      copyTimeout: null,
      opened: false,
      promocode: "",
    };
  },

  methods: {
    copyAmount() {
      navigator.clipboard.writeText(this.amount);
      this.copyTimeout = setTimeout(() => {
        this.copyTimeout = null;
      }, 1000);
    },

    open() {
      this.promocode = "";
      document.documentElement.style.overflow = "hidden";
      this.opened = true;
    },

    cancel() {
      document.documentElement.style.overflow = "";
      this.opened = false;
    },

    submit() {
      this.$emit("applyPromocode", this.promocode);
      document.documentElement.style.overflow = "";
      this.opened = false;
    },
  },

  computed: {
    amount() {
      return this.cryptoAmount;
    },
    copied() {
      return this.copyTimeout !== null;
    },
  },

  beforeUnmount() {
    if (this.copyTimeout) {
      clearTimeout(this.copyTimeout);
    }
  },
};
</script>

<style scoped src="../style.css"></style>
